import React from "react";
import {FiEdit, FiTrash2} from 'react-icons/fi'

import {
  Content,
  NameUser,
  OptionsUser,
  Icons,
  Units
} from './styles'
import { Item, RowInfo } from "../../pages/Admin/styles";

const returnStringUnits = (unidadesToString = "NENHUMA unidade") => {
  return(
    <RowInfo>
    <Item>
      {unidadesToString}
    </Item>
  </RowInfo>
  )
}
export const getComponentUnitsTable = (units, allUnits) => {
  let content;
  if (!Array.isArray(units) || !Array.isArray(allUnits)) return returnStringUnits();
  if (units.length !== 0 && allUnits.length !== 0) {
    content = units?.length === allUnits?.length
      ? "TODAS as unidades"
      : units.map((u) => u.unidade).join(", ");

    if (content.length > 110) { 
      content = content.substring(0, 110) + '...';
    }
  }
  if (!content || typeof content !== 'string') return returnStringUnits();
  return returnStringUnits(content);
}

export default function User({
  dataUser,
  handleCliskEdit,
  handleDelete,
}){
  return(
    <Content>
      <NameUser>{dataUser.nome}</NameUser>
      <Units>
        {dataUser?.unidades?.map(u => u.unidade).join(', ')}
      </Units>
      {/* <CargoUser>{dataUser.perfil.perfil}</CargoUser> */}
      <OptionsUser>
        <Icons setColor={'#FFDE59'} onClick={() => handleCliskEdit()}><FiEdit color="#fff" /></Icons>  
        <Icons setColor={'#E23636'} onClick={handleDelete}><FiTrash2 color="#fff" /></Icons>
      </OptionsUser>
    </Content>
  )
}